<template>
  <div class="EditPassword">
    <div class="container">
      <h1>修改密码</h1>
      <el-form :model="editPswForm" :rules="rules" ref="editPswForm" label-width="100px" class="demo-ruleForm" style="width: 450px;margin:0px auto">
        <el-form-item>
        </el-form-item>
        <el-form-item label="原密码" prop="oldpsw">
          <el-input v-model="editPswForm.oldpsw" placeholder="请输入原始密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newpsw">
          <el-input v-model="editPswForm.newpsw" placeholder="请输入新密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="newpsw_confirm">
          <el-input v-model="editPswForm.newpsw_confirm" placeholder="请再次输入新密码" show-password></el-input>
        </el-form-item>
        <div class="rules">
          <p>密码规则：</p>
          <p>1.请勿使用与账户名相同的密码</p>
          <p>2.至少要6-16位数字或大小写字母</p>
        </div>
      </el-form>
      <div style="text-align: center">
        <el-button type="primary" @click="subChangePass">确认修改</el-button>
        <el-button plain @click="resetForm('editPswForm')">重置</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import oucy from "../../../util/oucyUtil";
export default {
  name: 'EditPassword',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.editPswForm.newpsw_confirm!== '') {
          this.$refs.editPswForm.validateField('newpsw_confirm');
        }
        callback();
      }
    };
    const validatePass_confirm = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.editPswForm.newpsw) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      showGetCode: true,
      count: '',
      editPswForm: {
        oldpsw: '',
        newpsw: '',
        newpsw_confirm: ''
      },
      rules: {
        oldpsw: [
          {required: true, message: '请输入原密码', trigger: 'blur'},
        ],
        newpsw: [
          {required: true, validator: validatePass, trigger: 'blur'},
          {min: 6, max: 25, message: '长度在 6 到 25 个字符', trigger: 'blur'}
        ],
        newpsw_confirm: [
          {required: true, validator: validatePass_confirm, trigger: 'blur'},
        ]
      }
    }
  },
  mounted() {

  },
  methods: {
    resetForm: function(formName) {
      this.$refs[formName].resetFields();
    },
    subChangePass: function () {
      const me = this;
      me.$refs["editPswForm"].validate((valid) => {
        if (valid) {

          oucy.postRequest('/client/user/userauth/changePassByPass', {
            oldPass: me.editPswForm.oldpsw,
            newPass: me.editPswForm.newpsw,
            newPass_repeat: me.editPswForm.newpsw_confirm
          }).then(res => {
            me.$notify({
              title: '修改成功',
              message: '密码已经修改，你需要重新登录',
              type: 'success',
              onClose: function () {
                oucy.logout();
              }
            });
          })

        }
      });
    }
  }
}
</script>

<style scoped>
.container{
  text-align: left;
  width: 100%;
  height:800px;
  background: #FFFFFF;
  /*margin-top:-28px;*/
}
h1{
  font-size: 28px;
  font-weight: bold;
  color: #555C68;
  text-align: center;
  padding: 100px 0 30px 0;
}
/deep/ .el-form-item label:after {
  content: " ";
}

/deep/ .el-form-item__label {
  text-align-last: justify
}
/* 这里去除必选字段的*,这个符号会造成一定影响 */
/deep/ .el-form-item.is-required .el-form-item__label:before {
  content: none !important;
}
.rules p{
  color: #AAAAAA;
  font-size: 14px;
}
.rules{
  margin: 50px 0 100px 0;
}
</style>
